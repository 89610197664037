<template>
  <div class="service">
    <!-- 广告ban -->
    <div class="kf_ban"></div>
    <!-- 服务展示 -->
    <section class="industry_custom">
      <div class="w">
        <!-- 服务展示 -->
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con">
                <span></span>
                <h3>服务展示</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card">
          <el-row>
            <Intserv></Intserv>
            <el-card class="multiple1 right">
              <div class="left-block">服务分类</div>
              <div class="search">
                <el-input
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  class="input-style"
                >
                </el-input>
                <span class="search-icon"> 搜索</span>
              </div>
              <div class="service-tabs">
                <div class="tabs-title">服务类型:</div>
                <div class="tabs-list">
                  <div
                    class="list-item"
                    v-for="(ite, index) in type"
                    :key="index"
                  >
                    {{ ite.lab }}
                  </div>
                </div>
              </div>
            </el-card>
          </el-row>
        </div> -->
      </div>
    </section>

    <div class="w" style="padding-bottom: 50px">
      <Finance></Finance>
      <div class="zhans zhans1 clearfix">
        <div class="clearfix">
          <div class="L L_x left">
            <p class="headline">科技服务新选</p>
            <p class="explain">
              按照“友好合作、优势互补、合作共赢、共谋发展”的原则，选择、评估、遴选可转移转化的技术与成果，组织开展技术开发、技术转让、技术咨询、技术服务，致力于以服务国家创新驱动发展。
            </p>
            <div>
              <p class="to_zt"  @click="to_zt()">
                <span>进入专题页面></span>
              </p>
            </div>
          </div>
          <el-card class="right">
            <div class="Right_content">
              <ul>
                <li
                  class="dib"
                  v-for="(ie, index) in fwList"
                  :key="index"
                  @click="xq(ie.id)"
                >
                  <div class="dib r_nav left">
                    <div class="t_t">
                      <div class="map-inners-icon">
                        <span class="dib R_two">{{ ie.title }}</span>
                      </div>
                    </div>
                    <div class="three">
                      <div class="product">
                        <!-- <span>{{ ie.describe }}</span> -->
                        <span v-html="ie.describe" class="pro_span"></span>
                      </div>
                    </div>
                    <div class="price">
                      <span>{{ ie.cost }}元</span>
                    </div>
                    <div class="btn_last">
                      <span class="buy-btn">立即购买</span>
                      <span class="contact-btn"
                        ><i class="iconfont"></i>立即咨询</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </el-card>
        </div>
      </div>
      <Property></Property>
    </div>
  </div>
</template>

<script>
import { Sdisplay } from "../../../common/js/api";
import Intserv from "../../../common/intserv";
import Property from "../../../common/property";
import Finance from "../../../common/finance";
export default {
  name: "ServiceNei",
  components: {
    Intserv,
    Property,
    Finance,
  },
  data() {
    return {
      type: [
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
        {
          lab: "文案文案",
        },
      ],
      fwList: [],
    };
  },
  created() {
    this.getSdisplay();
  },
  methods: {
    getSdisplay() {
      Sdisplay()
        .then((res) => {
          console.log(res);
          this.fwList = res.data.two.slice(0, 6);
        })
        .catch(() => {});
    },
    // 进入专题页面
    to_zt() {
      this.$router.push({
        path: "/special",
        query: {
          s: "kjfw",
        },
      });
    },
    // 进入详情页
    xq(id) {
      this.$router.push({
        path: "/s_xq",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.service {
  background-color: rgba(248, 249, 250, 100);
  .kf_ban {
    background: url(../../../assets/img/kefuyun/fwzs.png) no-repeat center;
    background-size: 100% 100%;
	margin-top: 135px;
  }
}

.multiple1 {
  width: 964px;
  height: 380px;
}
// 服务展示

.left-block {
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px 5px 0px 0px;
  background-color: rgba(56, 146, 235, 100);
  color: rgba(248, 249, 250, 100);
  font-size: 20px;
}
/deep/.el-card__body {
  padding: 0;
}
// 服务分类
.search {
  display: flex;
  margin-top: 20px;
  padding: 50px 40px 63px;
  height: 40px;
  align-items: center;
}
.search .input-style {
  display: inline-block;
  width: 808px;
  border-radius: 20px;
  height: 40px;
  padding-left: 12px;
  background: #f7f7f7;
}
/deep/.el-input__inner {
  background: #f7f7f7;
  border: 0;
  border-radius: 20px;
}
.search .search-icon {
  display: flex;
  width: 100px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #0084ff;
  cursor: pointer;
}
.service-tabs {
  display: flex;
  padding: 0 48px 40px;
  font-size: 16px;
  background-color: #fff;
}
.service-tabs .tabs-title {
  color: #333;
  width: 88px;
  font-weight: 500;
  margin-top: 5px;
}
.service-tabs .tabs-list {
  width: 808px;
  color: #999;
}
.service-tabs .tabs-list .list-item {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 6px 14px;
  cursor: pointer;
}

.zhans1 {
  margin-top: 30px;
}

.L_x {
  width: 200px;
  height: 380px;
  color: #fff;
  padding: 46px 21px 0 23px;
  background: url(../../../assets/img/kefuyun/kj2.png) no-repeat;
  background-size: 100% 100%;
  .to_zt {
    color: #ed712e;
  }
}
/deep/.pro_span,
/deep/.pro_span p {
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
@media screen and (max-width: 1200px) {
	.kf_ban{
		margin-top: 15rem !important;
	}
	.w{
		width: 98%;
	}
	.copy-con h3{
		font-size: 2.2rem;
	}
	/deep/.L{
		width: 100%;
		height: 18rem;
		background-size: cover;
	}
	.L_x {
		width: 100%;
		height: 18rem;
		background-size: cover;
	}
	/deep/.headline{
		font-size: 2rem;
	}
	/deep/.explain{
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	/deep/.to_zt{
		width: 12rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.4rem;
	}
	/deep/.R_two{
		font-size: 1.6rem;
		text-overflow: ellipsis;
		    white-space: nowrap;
		    overflow: hidden;
	}

	/deep/.Right_content{
		height: auto;
	}
	/deep/.r_nav{
		height: auto;
	}
	/deep/.pro_span p{
		font-size: 1.4rem;
		height: auto;
	}
	/deep/.price{
		font-size: 1.6rem;
	}
	/deep/.btn_last .buy-btn{
		width: 5rem;
		font-size: 1.3rem;
		height: 2.3rem;
		line-height: 2.3rem;
	}
	/deep/.btn_last{
		font-size: 1.2rem;
	}
}
</style>