<template>
  <!-- 综合服务 -->
  <div class="multiple left">
    <div class="left-block">综合服务</div>
    <ul class="serve-list">
      <li class="serve-item" v-for="(item, index) in main_title" :key="index">
        <div class="title">
          <p class="icon">
            <img :src="item.img" class="img" />
          </p>
          <span>{{ item.title }}</span>
        </div>
        <p class="tip-list">
          <span v-for="para in item.text" :key="para.id">
            <router-link
              :to="para.url"
              v-if="para.url != undefined"
              target="_blank"
            >
              {{ para.name }}
            </router-link></span
          >
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      main_title: [
        {
          img: require("../assets/img/kefuyun/icon1.png"),
          title: "政策应用",
          text: [{ name: "政策精准查询", url: "member5/test5-1" }],
        },
        {
          img: require("../assets/img/kefuyun/icon2.png"),
          title: "技术升级",
          text: [
            { name: "需求发布", url: "need" },
            { name: "需求大厅", url: "hall" },
          ],
        },
        {
          img: require("../assets/img/kefuyun/icon4.png"),
          title: "成果服务",
          text: [
            { name: "成果查询 ", url: "member3/test3-1" },
            { name: "成果发布 ", url: "sucessresult" },
          ],
        },
        {
          img: require("../assets/img/kefuyun/icon3.png"),
          title: "知产服务",
          text: [{ name: "专利查询 ", url: "Patent" }],
        },

        {
          img: require("../assets/img/kefuyun/icon5.png"),

          title: "人才服务",
          text: [
            { name: "专家智库", url: "member7/test7-1" },
            { name: "人才发布", url: "position" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
// 综合服务
.multiple {
  width: 220px;
  height: 380px;
}
.left-block {
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px 5px 0px 0px;
  background-color: rgba(56, 146, 235, 100);
  color: rgba(248, 249, 250, 100);
  font-size: 20px;
}

.serve-list {
  width: 220px;
  height: 340px;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  line-height: 1;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}
.serve-item .title {
  justify-content: flex-start;
  font-size: 16px;
}
.serve-item {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.serve-item:nth-last-child(1) {
  border-bottom: 0;
}
.serve-list .serve-item .title .icon {
  position: absolute;
  top: 15px;
  width: 26px;
  height: 26px;
  margin-right: 12px;
}
.serve-list .serve-item .title .icon img {
  width: 100%;
}
.serve-list .serve-item .title span {
  margin-left: 32px;
}
.serve-list .serve-item .tip-list {
  margin-top: 8px;
 
  margin-left: 32px;
  a {
 color: #696969;
  }
}
.serve-list .serve-item .tip-list span {
  font-size: 12px;
  cursor: pointer;
}
.serve-list .serve-item .tip-list span + span {
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.copy-con h3{
		font-size: 3rem;
	}
	.copy-con{
		align-items: center;
		display: flex;
		
	}
	.multiple{
		width: 100%;
		height: auto;
	}
	.multiple ul{
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		height: auto;
	}
	.left-block{
		line-height: 4rem;
		height: 4rem;
		font-size: 2.3rem;
	}
	.serve-item{
		width: 50%;
		height: 5rem;
	}
	.serve-list .serve-item .title .icon{
		width: 3rem;
		height: 3rem;
	}
	.serve-list .serve-item .title span{
		margin-left: 4rem;
		font-size: 1.8rem;
	}
	.serve-list .serve-item .tip-list{
		margin-left: 4rem;
	}
	.serve-list .serve-item .tip-list span{
		font-size: 1.4rem;
	}
	
}
</style>