<template>
  <!-- 综合服务-知识产权        知识产权服务 -->
  <div class="zhans zhans1 clearfix">
    <div class="clearfix">
      <div class="L L_z left">
        <p class="headline">知识产权服务</p>
        <p class="explain">
          集专利、商标、软著等的技术成果综合为一体的全方位的服务活动，主要进行转让、登记、咨询等。
        </p>
        <div>
          <p class="to_zt" @click="to_zt()">
            <span>进入专题页面></span>
          </p>
        </div>
        <div class="zscq"></div>
      </div>
      <el-card class="right">
        <div class="Right_content">
          <ul>
            <li
              class="dib"
              v-for="(ie, index) in fwList"
              :key="index"
              @click="xq(ie.id)"
            >
              <div class="dib r_nav left">
                <div class="t_t">
                  <div class="map-inners-icon">
                    <span class="dib R_two">{{ ie.title }}</span>
                  </div>
                </div>
                <div class="three">
                  <div class="product">
                    <!-- <span>{{ ie.describe }}</span> -->
                    <span v-html="ie.describe" class="pro_span"></span>
                  </div>
                </div>
                <div class="price">
                  <span>{{ ie.cost }}元</span>
                </div>
                <div class="btn_last">
                  <span class="buy-btn">立即购买</span>
                  <span class="contact-btn"
                    ><i class="iconfont"></i>立即咨询</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { Sdisplay } from "./js/api";

export default {
  name: "property",

  data() {
    return {
      fwList: [],
    };
  },
  created() {
    this.getSdisplay();
  },
  methods: {
    getSdisplay() {
      Sdisplay()
        .then((res) => {
          this.fwList = res.data.three.slice(0, 6);
        })
        .catch(() => {});
    },
    // 进入专题页面
    to_zt() {
      this.$router.push({
        path: "/special",
        query: {
          s: "zscq",
        },
      });
    },
    // 进入详情页
    xq(id) {
      this.$router.push({
        path: "/s_xq",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
/deep/.el-card__body {
  padding: 0;
}

.zhans1 {
  margin-top: 30px;
}

.L_z {
  width: 200px;
  height: 380px;
  color: #fff;
  padding: 46px 21px 0 23px;
  background: url(../assets/img/kefuyun/kj3.png) no-repeat;
  background-size: 100% 100%;
  .to_zt {
    color: #2a9dde;
  }
  .zscq {
    width: 75px;
    height: 75px;
    margin: auto;
    background: url(../assets/img/kefuyun/zs.png) no-repeat;
    background-size: 100% 100%;
  }
}

/deep/.pro_span,
/deep/.pro_span p {
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>