<template>
	<!-- 综合服务-科技金融        科技金融服务 -->
	<div class="zhans clearfix">
		<div class="clearfix">
			<div class="L left">
				<p class="headline">科技金融服务</p>
				<p class="explain">通过创新财政科技投入，引导创新金融产品，搭建服务平台，实现科技与金融的有机结合。</p>
				<div>
					<p class="to_zt" @click="to_zt()"><span>进入专题页面></span></p>
				</div>
			</div>
			<el-card class="right">
				<div class="Right_content">
					<ul>
						<li class="dib" v-for="(ie, index) in fwList" :key="index" @click="xq(ie.id)">
							<div class="dib r_nav left">
								<div class="t_t">
									<div class="map-inners-icon">
										<span class="dib R_two">{{ ie.title }}</span>
									</div>
								</div>
								<div class="three">
									<div class="product">
										<!-- <span>{{ ie.describe }}</span> -->
										<span class="pro_span" v-html="ie.describe"></span>
									</div>
								</div>
								<div class="price">
									<span>{{ ie.cost }}元</span>
								</div>
								<div class="btn_last">
									<span class="buy-btn">立即购买</span>
									<span class="contact-btn">
										<i class="iconfont"></i>
										立即咨询
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</el-card>
		</div>
	</div>
</template>
<script>
import { Sdisplay } from './js/api';
export default {
	name: 'finance',

	data() {
		return {
			fwList: []
		};
	},
	created() {
		this.getSdisplay();
	},
	methods: {
		getSdisplay() {
			Sdisplay()
				.then(res => {
					this.fwList = res.data.one.slice(0, 6);
				})
				.catch(() => {});
		},
		// 进入专题页面
		to_zt() {
			this.$router.push({
				path: '/special',
				query: {
					s: 'kjjr'
				}
			});
		},
		// 进入详情页
		xq(id) {
			this.$router.push({
				path: '/s_xq',
				query: {
					id: id
				}
			});
		}
	},
	mounted() {}
};
</script>
<style scoped lang="less">
/deep/.el-card__body {
	padding: 0;
}

.zhans {
	margin-top: 14px;
}
.L {
	width: 200px;
	height: 380px;
	color: #fff;
	padding: 46px 21px 0 23px;
	background: url(../assets/img/kefuyun/kj.png) no-repeat;
	background-size: 100% 100%;
}
.to_zt {
	color: #5a4eb3;
}
/deep/.pro_span,
/deep/.pro_span p {
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>
